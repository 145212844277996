import { useParams } from "react-router-dom";
import { Typography, Grid, Link, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useReducer, useState, useMemo } from "react";
import Layout from "../components/layout";
import { useTrackEvent } from "../hooks/useTrackEvent";
import { fetchPostBySlug } from "../redux/actions/posts/fetchPostBySlug";
import { fetchPostAuthor } from "../redux/actions/posts/fetchPostAuthor";
import { CompositeDecorator, EditorState, convertFromRaw, Editor } from 'draft-js';
import { getPostsPage } from "../redux/actions/posts/fetchPostsPage";
import Image from 'react-bootstrap/Image';
import { PostsBySlugReducer } from "../redux/reducers/posts/fetchPostBySlugReducer";
import { PostAuthorReducer } from "redux/reducers/posts/fetchPostAuthor";
import { PostsPageReducer } from "redux/reducers/posts/fetchPostsPage";
import { Media } from 'reactstrap';
import { fetchUserImageReducer } from "redux/reducers/users/fetchUserImageReducer";
import fetchUserImage from "redux/actions/users/fetchUserImage";
import { Buffer } from 'buffer';
import PostTags from "components/posts/tagsComponent";

const useRowStyles = makeStyles(() => ({
  postRoot: {
    background: "white",
  },
  title: {
    fontFamily: "OpenSans-Bold !important",
    color: "rgba(48, 65, 140, 1)",
    paddingTop: "2rem",
    paddingLeft: "3rem",
    paddingBottom: "0.5rem",
    "@media(max-width: 600px)": {
      fontSize: "2rem !important",
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  },
  moreContentTitle: {
    fontFamily: "OpenSans-Bold !important",
    fontSize: "1.5rem",
    color: "rgba(48, 65, 140, 1)",
    paddingTop: "2rem",
    paddingLeft: "3rem",
    paddingBottom: "2.5rem",
    
    "@media(max-width: 600px)": {
      padding: "0px",
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
  otherPostsItemClean: {
    background: "white",
    "@media(max-width: 600px)": {
      height: "0px"
    },
  },
  author: {
    paddingLeft: "3rem",
    paddingBottom: "0.5rem",
    color: "gray",
    fontSize: "1.5rem",

    "@media(max-width: 600px)": {
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  },
  authorContainer: {
    paddingLeft: "3rem",
    paddingBottom: "0.5rem",

    "@media(max-width: 600px)": {
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  },
  playerContainer: {
    position: "sticky",
    padding: "1rem",
    height: "100%",

   // [theme.breakpoints.down("md")]: {
   //   position: "relative",
     // padding: "0rem",
   // },
  },
  otherPostsTitle: {
    fontFamily: "OpenSans-Bold !important",
    fontSize: "1.5rem",
    color: "primary",
    paddingTop: "1rem",
    paddingBottom: "0.5rem",
  },
  otherPostsItem: {
    paddingTop: "1rem",
    background: "white",
  },
  otherPostsContainer: {
    paddingLeft: "1rem",
    paddingTop: "1rem",
    display: "flex",
    "@media(max-width: 600px)": {
      padding: "0px",
    },
  },
  contentImageContainer: {
    textAlign: "center",
  },
  contentImage: {
    minHeight: "100px",
    maxHeight: "200px",
    "@media(max-width: 600px)": {
      maxHeight: "100px",
    },
  },
  content: {
    fontFamily: "OpenSans-Light !important",
    textAlign: "left",
    paddingLeft: "3rem",
    paddingTop: "1%",
    paddingBottom: "2%",

    '& p': {
      fontSize: "1.5rem"
    },

    "@media(max-width: 600px)": {
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  },
  label: { fontFamily: "OpenSans-Light !important", fontSize: "0.8rem" },
  image: { padding: "0rem", alignItems: "center", '&:hover': { transform: "rotate(-45deg)" } },
}));

const Post = () => {
  const { slug } = useParams<{ slug: string }>();
  const classes = useRowStyles();
  const { trackEvent } = useTrackEvent();
  const [postBySlug, dispatchPostBySlug] = useReducer(PostsBySlugReducer, null);
  const [postAuthor, dispatchAuthor] = useReducer(PostAuthorReducer, null);
  const [postsPage, dispatchPostsPage] = useReducer(PostsPageReducer, []);
  const [userImage, dispatchUserImage] = useReducer(fetchUserImageReducer, null);
  const random = Math.floor(Math.random() * 50) + 1;
  const decorator = createLinkDecorator();
  const { owner_id = "", text = "", title = "", tags = [] } = postBySlug ?? {};
  const { name = "", lastName = "", bio = "" } = postAuthor ?? {};
  const userSlug = postAuthor?.slug ?? "";
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const getPostBySlug = async () => {
    const response = await fetchPostBySlug(slug);
    dispatchPostBySlug({ type: "FETCH_POST_BY_SLUG", postBySlug: response });
  }

  const getPostAuthor = async () => {
    const response = owner_id !== "" ? await fetchPostAuthor(owner_id) : null;
    dispatchAuthor({ type: "FETCH_POST_AUTHOR", postAuthor: response });
  }

  const getOtherPostsPage = async (limit: number) => {
    const response = await getPostsPage(limit, random);
    dispatchPostsPage({ type: "FETCH_POSTS_PAGE", postsPage: response });
  }

  const transformContent = () => {
    try{
      const content = JSON.parse(text) ?? EditorState.createEmpty();
      content.entityMap === undefined ? content.entityMap = {} : content.entityMap = content.entityMap;
      const convertedContent = convertFromRaw(content);
      setEditorState(EditorState.createWithContent(convertedContent, decorator));
    }
    catch(e){console.log(e)}
  }

  const getUserImage = async (type: String, userSlug: String) => {
    const response = await fetchUserImage(type, userSlug);
    if (response === "not Found") {
      const data = "";
      dispatchUserImage({ type: "FETCH_USER_IMAGE", userImage: data });
  } else {
      const buffer = response.file.data;
      const data = Buffer.from(buffer.data, "binary").toString("base64");
      dispatchUserImage({ type: "FETCH_USER_IMAGE", userImage: data });
    }
  }   
    
  useEffect(() => {
    getPostBySlug();
  }, [slug]);

  useEffect(() => {
    transformContent();
  }, [postBySlug]);

  const renderContent = () => {
    return (
      <Editor
        readOnly
        editorState={editorState}
      />)
    };

  const renderAuthor = useMemo(() => {
    getPostAuthor();
    userSlug !== "" && getUserImage("profile", userSlug);
    return (
      <Media>
        <Media  
          href={
              postAuthor !== null
                ? "/equipo/" + userSlug
                : "contenido/posts"
            }>
            <Media
                object
                src={userImage ? `data:image/jpeg;base64,${userImage}` : "../../ISOTNO5050.webp"}
                alt= {`${name} ${lastName}`}
                width="60"
                className="round-media"
            />
      </Media>
      <Media body>
          <Media heading className="primary">
              {`Escrito por: ${name} ${lastName}`}
          </Media>
          {bio}
      </Media>
      </Media>
  )}, [postBySlug, userSlug, userImage]);

  const renderOtherPosts = useMemo(() => {
    getOtherPostsPage(6);
    return (   
     <>
      {postsPage?.map((post) =>
        <Link
          key={post._id}
          underline="none"
          rel="noopener"
          href={`/contenido/${post.slug}`}
          onClick={() => {
            trackEvent("posts", "click", "post_title_click");
          }}
            >
              <Grid container>
                <Grid item container xs={2} sm={1} justifyContent="center" className={classes.image}>
                  <Image
                    roundedCircle
                    height={40}
                    src="../../ISOTNO5050.webp"
                  />
                    </Grid>
                <Grid item xs={10} sm={11}>
                  <Typography className={classes.otherPostsTitle}>
                      {post.title}
                  </Typography>
                  <Divider variant="middle"/>
                </Grid>
              </Grid>
        </Link>
      )}
     </>
  )}, [postBySlug]);

  return (
    <Layout>
      <div className={classes.postRoot}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={8}>
            <PostTags tags={tags} tagSize="1.5rem" isHome={false}/>
            <Typography
              className={classes.title}
              variant="h2"
              align="left"
              gutterBottom
            >
              {title}
            </Typography>
            <Typography variant="subtitle1" className={classes.content} gutterBottom>
              {renderContent()}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.authorContainer}>
            {renderAuthor}
          </Grid>
          <Grid item xs={12} md={7}></Grid>
          <Grid item xs={12} md={8} className={classes.otherPostsItem}>
            <Typography className={classes.moreContentTitle}>
              También te puede interesar
              {renderOtherPosts}
          </Typography>
          </Grid>
          <Grid item xs={12} md={4} className={classes.otherPostsItemClean}>
          </Grid>
        </Grid>     
      </div>
    </Layout>
  );
};

export default Post;

const LinkDecorator = ({ entityKey, contentState, children }) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return <a href={url}>{children}</a>;
};

const ImageDecorator = ({ entityKey, contentState }) => {
  const { src } = contentState.getEntity(entityKey).getData();
  const classes = useRowStyles();
  return <div className={classes.contentImageContainer}><Image src={src} className={classes.contentImage} /></div>;
};

const EmbeddedLinkDecorator = ({ entityKey, contentState, children }) => {
  const { src, height, width } = contentState.getEntity(entityKey).getData();
  return <iframe src={src} height={height} width={width} title="Radio Visual Online">{children}</iframe>;
};

const findLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const findImageEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "IMAGE"
    );
  }, callback);
};

const findEmbededLinkEntities = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();

    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "EMBEDDED_LINK"
    );
  }, callback);
};

const createLinkDecorator = () =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: LinkDecorator
    },
    {
      strategy: findEmbededLinkEntities,
      component: EmbeddedLinkDecorator
    },
    {
      strategy: findImageEntities,
      component: ImageDecorator
    },
]);